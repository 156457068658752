<template>
  <div class="home">
    <div class="top">
      <el-row :gutter="13">
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #84D6FF 0%, #6F93FB 100%);">
                <img src="/static/images/homepage/rzy.png"/>
              </div>
              <div class="right">
                <div>{{$l("home.parts", "设备总数")}}</div>
                <div>
                  <span class="number">{{elevatorTotalNum}}</span>
                  <span>台</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #6DEEB6 0%, #7194FC 100%);">
                <img src="/static/images/homepage/rwzs.png"/>
              </div>
              <div class="right">
                <div>{{$l("home.lifts", "入网总数")}}</div>
                <div>
                  <span class="number">{{elevatorInNetCount}}</span>
                  <span>台</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #7194FC 0%, #AE84FF 100%);">
                <img src="/static/images/homepage/zxzs.png"/>
              </div>
              <div class="right">
                <div>{{$l("home.onlineLifts", "在线总数")}}</div>
                <div>
                  <span class="number">{{elevatorOnlineCount}}</span>
                  <span>台</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #FDBD50 0%, #FF7E48 100%);">
                <img src="/static/images/homepage/dqjx.png"/>
              </div>
              <div class="right">
                <div>{{$l("home.repairLifts", "当前急修")}}</div>
                <div>
                  <span class="number"> {{workOrderNum}}</span>
                  <span>台</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="middle">
      <el-row :gutter="16">
        <el-col :span="8">
          <div class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{$l("home.elevatorStatus", "电梯状态")}}</div>
              </div>
            </div>
            <div class="bottom">
              <elevator-status-echarts-pie></elevator-status-echarts-pie>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{$l("home.faultType", "故障类型")}}</div>
              </div>
              <div style="flex: 1;text-align: right;">
                <el-date-picker
                  v-model="dateRange"
                  style="width: 70%;border-radius: 8px;"
                  type="daterange"
                  :range-separator="$t('common.to')"
                  :start-placeholder="$t('common.startDate')"
                  :end-placeholder="$t('common.endDate')"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
            </div>
            <div class="bottom">
              <fault-type-echarts-bar :date="dateRange"></fault-type-echarts-bar>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div v-loading="docsLoading" class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{$l("home.documents", "对接文档")}}</div>
              </div>
            </div>
            <div class="bottom" style="padding-top: 10px;padding-left: 28px;">
              <div v-for="(item, index) in docs" :key="index" style="margin-bottom: 15px">
                <i class="el-icon-document-copy" style="font-size: 14px;margin-right: 8px;"></i>
                <el-link type="primary" style="color: #0747FD;" @click="downloadDoc(item.accessDoc.url)">
                  {{
                    item.accessDoc.filename
                  }}
                </el-link>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <el-row :gutter="16">
        <el-col :span="8">
          <div v-loading="mbsLoading" class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{$l("home.events", "实时事件")}}</div>
              </div>
              <div style="flex: 1;text-align: right;color: #0747FD;font-size: 14px;cursor: pointer;">
                <span @click="goTo('deviceEvent')">更多></span>
              </div>
            </div>
            <div class="bottom" style="padding: 0;border-radius:0 0 12px 12px">
              <el-table :show-header="false" :data="mbs" style="width: 100%;" :row-class-name="tableRowClassName">
                <el-table-column prop="elevatorName" show-overflow-tooltip></el-table-column>
                <el-table-column prop="eventName" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="eventTime">
                  <template slot-scope="scoped">
                    <span style="color: #808080;">
                      {{scoped.row.eventTime}}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div v-loading="faultLoading" class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{$l("home.faults", "实时故障")}}</div>
              </div>
              <div style="flex: 1;text-align: right;color: #0747FD;font-size: 14px;cursor: pointer;">
                <span @click="goTo('faultRecord')">更多></span>
              </div>
            </div>
            <div class="bottom" style="padding: 0;border-radius:0 0 12px 12px">
              <el-table :show-header="false" :data="faults" style="width: 100%;" :row-class-name="tableRowClassName">
                <el-table-column prop="elevatorName" show-overflow-tooltip></el-table-column>
                <el-table-column prop="faultDesc" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime">
                  <template slot-scope="scoped">
                    <span style="color: #808080;">
                      {{scoped.row.faultTime}}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div v-loading="alarmsLoading" class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{$l("home.alarms", "警铃报警")}}</div>
              </div>
              <div style="flex: 1;text-align: right;color: #0747FD;font-size: 14px;cursor: pointer;">
                <span @click="goTo('deviceEvent','报警触发装置动作')">更多></span>
              </div>
            </div>
            <div class="bottom" style="padding: 0;border-radius:0 0 12px 12px">
              <el-table :show-header="false" :data="alarms" style="width: 100%;" :row-class-name="tableRowClassName">
                <el-table-column prop="elevatorName" show-overflow-tooltip></el-table-column>
                <el-table-column prop="eventName">
                </el-table-column>
                <el-table-column prop="eventTime">
                  <template slot-scope="scoped">
                    <span style="color: #808080;">
                      {{scoped.row.eventTime}}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  // import VmInfoCard from "@/components/VmInfoCard.vue";
  // import ChinaMap from "./China.vue";
  import validateUtil from "@/util/validateUtil";
  import ElevatorStatusEchartsPie from "@/views/KanBan/ElevatorStatusEchartsPie";
  import FaultTypeEchartsBar from "@/views/KanBan/FaultTypeEchartsBar";

  export default {
    components: {
      ElevatorStatusEchartsPie,
      FaultTypeEchartsBar,
      // VmInfoCard, ChinaMap,
    },
    data() {
      return {
        loading: false,
        eventsLoading: false,
        faultLoading: false,
        mbsLoading: false,
        alarmsLoading: false,
        docsLoading: false,
        faultRecordAuth: this.$auth(117),
        alarmRecordAuth: this.$auth(341),
        notices: [],
        docs: [],
        mbs: [],
        faults: [],
        alarms: [],
        elevatorCountData: [],
        dateRange: [this.$moment().subtract(1, "month").format("YYYY-MM-DD"), this.$moment().format("YYYY-MM-DD")],
        elevatorOnlineCount: 0,
        workOrderNum: 0,
        elevatorTotalNum: 0,
        elevatorInNetCount: 0,
        userCount: 0,
        barPointWidth: 20,
        bannerFiles: [],
        publicNoticeSvo: {
          pageSize: 20,
          pageNo: 1,
          allData: 1,
        },
        pieLegend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
          itemMarginTop: 30,
          itemMarginBottom: 35,
        },
        faultType: {
          data: [],
          categories: [],
          color: ["#564bf5", "#008fe0", "#73c6f6", "#299458", "#5b83b9", "#9f9f9f"],
        },
      };
    },
    mounted() {
      this.getDocs();
      this.getMbs();
      this.getAlarms();
      // this.getWorkOrderCount();
      if (this.$auth(117)) {
        this.getFaults();
      }
      this.getOnlineElevatorCount();
      this.getElevatorTotalNum();
      this.getElevatorInNetCount();
      this.getUserCount();
      this.getBanners();
      this.showPwdSimple();
      setInterval(() => {
        if (this.faultRecordAuth) {
          this.getFaults();
        }
      }, 1000 * 60);
    },
    methods: {
      noticeMore() {
        this.$router.push({path: "publicNoticeMore"});
      },
      // 入网总数
      getElevatorInNetCount() {
        let param = {
          pageSize: 20,
          pageNo: 1,
          inNetState: true,
        };
        this.$http.get("elevators/page", param).then(res => {
          this.elevatorInNetCount = res.data.total;
        });
      },
      getUserCount() {
        this.$api.getData("users/count").then(res => {
          this.userCount = res.data;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getBanners() {
        this.$api.getData("uiSettings/current").then(res => {
          if (res.data.banners) {
            for (const banner of JSON.parse(res.data.banners)) {
              this.bannerFiles.push({url: this.getFullUrl(banner)});
            }
          }
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getFullUrl(path) {
        return window.config.SERVER_URL + path;
      },
      showPwdSimple() {
        let password = this.$route.params.password;
        if (password && password.length) {
          let pass = validateUtil.validatePassword(password);
          if (!pass) {
            this.$confirm("密码过于简单，建议您修改密码", this.$t("common.tips"), {
              confirmButtonText: "修改密码",
              cancelButtonText: "忽略",
              type: "warning",
              closeOnClickModal: false,
            }).then(() => {
              this.$router.push({
                path: "/account",
                query: {activeTab: "second"},
              });
            });
          }
        }
      },

      // 查询对接文档
      getDocs() {
        this.docsLoading = true;
        this.$api.getData("integrations/list/100").then(res => {
          this.docs = res.data;
          this.docsLoading = false;
        }).catch(error => {
          this.docsLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      // 对接文档下载
      downloadDoc(url) {
        const isHttps = "https:" === document.location.protocol;
        if (isHttps) {
          window.open(url.replace("http:", "https:"));
        } else {
          window.open(url);
        }
      },
      // 表格偶数行高亮
      tableRowClassName({row, rowIndex}) {
        if (rowIndex % 2 === 1) {
          return "highting-row";
        }
      },
      // 实时故障
      getFaults() {
        this.faultLoading = true;
        let params = {
          pageSize: 10,
        };
        this.$api.getData("faultRecords", params).then(res => {
          this.faults = res.data.records;
          this.faultLoading = false;
        }).catch(error => {
          this.faultLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      // 实时事件
      getMbs() {
        this.mbsLoading = true;
        let params = {
          pageSize: 10,
          isAlarmBell: false,
        };
        this.$api.getData("deviceEvent", params).then(res => {
          this.mbs = res.data.records;
          this.mbsLoading = false;
        }).catch(error => {
          this.mbsLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getAlarms() {
        this.alarmsLoading = true;
        let params = {
          pageSize: 10,
          isAlarmBell: true,
        };
        this.$api.getData("deviceEvent", params).then(res => {
          this.alarms = res.data.records;
          this.alarmsLoading = false;
        }).catch(error => {
          this.alarmsLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      // 在线总数
      getOnlineElevatorCount() {
        let param = {
          pageSize: 20,
          pageNo: 1,
          inNetState: true,
          onlineStatus: 1,
        };
        this.$http.get("elevators/page", param).then(res => {
          this.elevatorOnlineCount = res.data.total;
        });
      },
      // 设备总数
      getElevatorTotalNum() {
        let param = {
          pageSize: 20,
          pageNo: 1,
        };
        this.$http.get("elevators/page", param).then(res => {
          this.elevatorTotalNum = res.data.total;
        });
      },
      // 当前急修
      getWorkOrderCount() {
        let param = {
          pageNo: 1,
        };
        this.$http.get("workOrders/unFinishedRecord",param).then(res => {
          this.workOrderNum = res.data;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      // 更多
      goTo(route,data) {
        this.$router.push({
          path:"/deviceEvent",
          query:{
            filter:data,
          }
        }).catch(error => error);
      },
    },
  };
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;

  .itemTitle {
    display: flex;
    color: #4D4D4D;

    .line {
      width: 4px;
      height: 16px;
      background-color: #0747FD;
      border-radius: 100px;
      margin-right: 8px;
    }
  }

  .top {
    height: 100px;

    .itemContent {
      padding: 18px 32px;
      height: 100%;
      box-sizing: border-box;
      display: flex;

      .left {
        width: 64px;
        height: 64px;
        background-color: #f2f2f2;
        margin-right: 14px;
        border-radius: 32px;
      }

      .right {
        flex: 1;
        color: #4D4D4D;

        div {
          height: 32px;

          .number {
            font-size: 32px;
            margin-right: 5px;
            font-weight: 700;
            color: #000;
          }
        }
      }
    }
  }

  .middle,
  .footer {
    height: 0;
    flex: 1;
    margin: 24px 0 16px 0;

    .itemBox {
      display: flex;
      flex-direction: column;
      position: relative;

      .top {
        display: flex;
        align-items: center;
        height: 48px;
        border-bottom: 1px solid #F2F2F2;
        justify-content: space-between;
        padding: 0 16px;
        box-sizing: border-box;
      }

      .bottom {
        flex: 1;
        overflow: auto;
        padding: 0 16px;
        box-sizing: border-box;
      }
    }
  }

  .footer {
    margin-top: 0;
    margin-bottom: 32px;
  }

  // 共用样式
  .el-row,
  .el-col {
    height: 100%;
  }

  .itemBox {
    height: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
<style>
.home {

.el-table {

tr {

td {

&
:first-child {
  padding-left: 18px !important;
}

&
:last-child {
  padding-right: 6px !important;
}

}
}
}
}
</style>
