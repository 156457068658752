<template>
  <div style="height: 100%" v-loading="loading">
    <el-empty v-if="isShow" description="周期内无故障" style="width: 100%; height: 100%"></el-empty>
    <div v-else id="faultType" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: Array
    }
  },

  watch: {
    date: {
      handler(value) {
        if (value) {
          this.dateRange[0] = this.$moment(value[0]).format("YYYY-MM-DD 00:00:00");
          this.dateRange[1] = this.$moment(value[1]).format("YYYY-MM-DD 23:59:59");
          this.getFaultTypeCount();
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      xData: [],
      yData: [],
      dateRange: [],
      isShow: false,
      loading: false
    };
  },

  methods: {
    getFaultTypeCount() {
      this.loading = true;
      this.$http.post("faultRecords/type/count", this.dateRange).then(res => {
        if (res.data.length) {
          res.data.sort((a, b) => {
            return a.total - b.total;
          });
          this.yData = res.data.map((item) => {
            return item.name;
          });
          this.xData = res.data.map((item) => {
            return item.total;
          });
          if (this.xData.length > 1) {
            if (this.xData.at(0) > 100 && this.xData.at(0) > this.xData.at(1) * 2) {
              this.xData[0] = Math.floor((this.xData.at(1) / 0.618));
            }
          }
          this.isShow = false;
          this.$nextTick(() => {
            this.initCharts();
          });
        } else {
          this.isShow = true;
        }
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },

    initCharts() {
      let myChart = this.$echarts.init(document.getElementById("faultType"));
      let option = {
        tooltip: {},
        grid: {
          top: 20,
          bottom: 20,
          left: 10,
          right: 10,
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: function (value) {
              if (value >= 1000) {
                return (value / 1000) + "k";
              } else {
                return value;
              }
            }
          }
        },
        yAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: this.yData,
          axisLabel: {
            formatter: function (value) {
              if (value.length > 5) {
                return value.substring(0, 5) + "..."; // 超出部分显示省略号
              } else {
                return value;
              }
            }
          },
        },
        series: [
          {
            type: "bar",
            data: this.xData,
            barWidth: 16,
            itemStyle: {
              normal: {
                color: function (params) {
                  let colors = ["#CDDAFF", "#B4C7FF", "#8FACFE", "#6A90FE", "#4575FD", "#0747FD"];
                  return colors[params.dataIndex];
                }
              }
            }
          },
        ]
      };
      myChart.setOption(option);
    },
  }
};
</script>